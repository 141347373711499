import axios from "axios";

let apiHandle = axios.create({
  baseURL: "https://backend.svips.se/",
});

let Get = (endPoint) => {
  return apiHandle.get(endPoint);
};
let GetThePage = (endPoint, page) => {
  return apiHandle.get(`${endPoint}?page=${page}`);
};
let GetById = (endPoint, id) => {
  return apiHandle.get(`${endPoint}/${id}`);
};
let Post = (endPoint, body) => {
  return apiHandle.post(`${endPoint}`, body);
};
// let Put = (endPoint, id, body) => {
//   return apiHandle.put(`${endPoint}/${id}`, body);
// };
let Put = (endPoint, id, body, token) => {
  // Assuming apiHandle supports headers, you can set the token header like this
  const headers = {
    Authorization: `Bearer ${token}`,
    // Add any other headers if needed
  };

  // Assuming apiHandle supports a configuration object as the second argument
  return apiHandle.put(`${endPoint}/${id}`, body, { headers });
};
let Putuser = (endPoint, id, body) => {
  return apiHandle.put(`${endPoint}/${id}`, body);
};
let Delete = (endPoint, id) => {
  return apiHandle.delete(`${endPoint}/${id}`);
};
let DeletePost = (endPoint, id, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    // Add any other headers if needed
  };
  return apiHandle.delete(`${endPoint}/${id}`, { headers });
};
export { Get, GetThePage, GetById, Post, Put, Delete, DeletePost, Putuser };
