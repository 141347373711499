import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import { Delete } from "ApiConfig/ApiMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Putuser } from "ApiConfig/ApiMethod";
import { Post } from "ApiConfig/ApiMethod";
import Detail from "./Detail";
import { IoSend } from "react-icons/io5";

// import { Delete } from "Api Config/ApiMethod";

const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [userData, setUserData] = useState([]);
  const [userID, setuserID] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blockuser, setblockuser] = useState(false);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [adminMsgModel, setadminMsgModel] = useState({
    status: true,
    detail: {
      id: "",
      name: "",
      type: "",
    },
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePath, setSelectedImagePath] = useState(null);
  const navigate = useNavigate("");
  const myId = localStorage.getItem("userID");
  const alluser = () => {
    fetch("https://backend.svips.se/getAllUsers")
      .then((response) => response.json())
      .then((data) => {
        setUserData(data); // Assuming the data is an array of user objects
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImagePath(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    alluser();
  }, [blockuser]);

  const handleClose = () => {
    openModal(null);
    setSelectedImage(null);
    setadminMsgModel({
      status: false,
      detail: { id: "", name: "", type: "" },
    });
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const openModal = (userid) => {
    setuserID(userid);
    setShow(true);
  };

  const SaveAmount = () => {
    setShow(false);
    const formData = new FormData();
    formData.append("userCommission", value);
    Post(`https://backend.svips.se/user/commission/${userID}`, {
      userCommission: value,
    })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteRow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        Delete("user/account/delete", e)
          .then((res) => {
            alluser();
            setLoading(false);
            toast.success("Deletion successful");
          })
          .catch((e) => console.log(e));
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error",
        });
      }
    });
  };

  const toggleBlock = (userId, isBlocked) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${isBlocked ? "unblock" : "block"} this user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${isBlocked ? "Unblock" : "Block"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          isBlock: !isBlocked, // Toggle the block state
        };
        Putuser("userBlock", userId, payload)
          .then((res) => {
            // Update the local state immediately
            const updatedUserData = userData.map((user) =>
              user._id === userId ? { ...user, blocked: !isBlocked } : user
            );
            setUserData(updatedUserData);
            alluser();

            toast.success(`${isBlocked ? "Unblock" : "Block"} successful`);
          })
          .catch((e) => {
            console.log(e);
          });
        Swal.fire({
          title: `${isBlocked ? "Unblocked" : "Blocked"}!`,
          text: `Your User has been ${isBlocked ? "Unblocked" : "Blocked"}.`,
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error",
        });
      }
    });
  };
  const handleOpen = (id) => {
    setSelectedId(id); // Store the selected ID if needed
    setOpen(true);
  };

  const openModalForAdminMsg = (id, name, type) => {
    setShow(true);
    setadminMsgModel({
      status: true,
      detail: { id, name, type },
    });
  };
  const sndMsg = () => {
    const formData = new FormData();
    formData.append("id", myId);
    formData.append("message", value);
    formData.append("attachment", selectedImagePath);
    formData.append("type", selectedImagePath ? "image" : "text");
    if (adminMsgModel?.detail?.id !== "") {
      formData.append("userId", adminMsgModel?.detail?.id);
    }

    Post(`https://backend.svips.se/admin/send`, formData)
      .then((res) => {
        setShow(false);
        setShow(false);
        setadminMsgModel({
          status: false,
          detail: { id: "", name: "", type: "" },
        });
        setSelectedImage(null);
        setValue("");
        setSelectedImagePath("");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      className="scroll-container"
      style={{
        maxHeight: "100vh", // Make sure the container has a height limit
        overflowY: "auto", // Enable vertical scrolling
        position: open ? "relative" : "static", // Conditionally apply position
        zIndex: open ? -1 : "auto", // Conditionally apply zIndex
      }}
    >
      <DashboardLayout>
        <DashboardNavbar />
        <Modal style={{ marginTop: "80px" }} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "18px" }}>
              {adminMsgModel.status
                ? adminMsgModel.detail.type === "SingleMsg"
                  ? `Write Any Message For ${adminMsgModel.detail.name}:`
                  : "Write Any Message For All:"
                : "WithDraw"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {adminMsgModel.status ? (
                <>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      onChange={(e) => setValue(e.target.value)}
                      type="text"
                      placeholder="Type a message.."
                      className="p-2"
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Choose Image</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="p-2"
                      autoFocus
                    />
                  </Form.Group>
                  {selectedImage && (
                    <div>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ width: "100px", height: "100px", objectFit: "cover" }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Give Amount:</Form.Label>
                    <Form.Control
                      onChange={(e) => setValue(e.target.value)}
                      type="number"
                      placeholder="$"
                      className="p-2"
                      autoFocus
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            {adminMsgModel.status ? (
              <Button
                disabled={!selectedImage && value === ""}
                variant="primary"
                onClick={() => sndMsg(adminMsgModel.detail.id)}
              >
                <>
                  Send Message <IoSend />
                </>
              </Button>
            ) : (
              <Button variant="primary" onClick={SaveAmount}>
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Users
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <div className="messageBtnDiv">
                    <button
                      onClick={() => openModalForAdminMsg("", "", "")}
                      className="btn btn-info"
                      style={{
                        background: "linear-gradient(195deg, #4CAF50, #8BC34A)",
                        color: "white",
                      }}
                      // disabled={row.row.original.blocked === true}
                    >
                      Leave Any Message For All Users.
                    </button>
                  </div>
                  {loading ? (
                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                    </div>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Name", accessor: "name", align: "left" },
                          { Header: "Date Of Birth", accessor: "dateOfBirth", align: "left" },
                          { Header: "Phone Number", accessor: "phone", align: "left" },
                          { Header: "User Verified", accessor: "verifiedUser", align: "left" },
                          { Header: "Gender", accessor: "gender", align: "  " },
                          {
                            Header: "Details",
                            accessor: "details",
                            align: "center",
                            Cell: (row) => {
                              return (
                                <button
                                  onClick={() => handleOpen(row?.row?.original?.name?.props?.id)}
                                  className="btn btn-info"
                                  style={{
                                    background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                                    color: "white",
                                  }}
                                  // disabled={row.row.original.blocked === true}
                                >
                                  Details
                                </button>
                              );
                            },
                          },
                          {
                            Header: "Transactions",
                            accessor: "action",
                            align: "center",
                            Cell: (row) => {
                              const hasVerifiedUser = row?.row?.original?.verifiedUser;
                              return (
                                <div>
                                  {hasVerifiedUser === "Yes" ? (
                                    <button
                                      onClick={() => navigate("/Transactionslist")}
                                      className="btn btn-info"
                                      style={{
                                        background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                                        color: "white",
                                      }}
                                      disabled={row.row.original.blocked === true}
                                    >
                                      Transactions
                                    </button>
                                  ) : (
                                    "No Transactions"
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Amount",
                            accessor: "amount",
                            align: "center",
                            Cell: (row) => {
                              const hasVerifiedUser = row?.row?.original?.verifiedUser;
                              return (
                                <div>
                                  {hasVerifiedUser === "Yes" ? (
                                    <button
                                      onClick={() => openModal(row?.row?.original?.name?.props?.id)}
                                      className="btn btn-info"
                                      style={{
                                        background: "linear-gradient(195deg, #4CAF50, #8BC34A)",
                                        color: "white",
                                      }}
                                      disabled={row.row.original.blocked === true}
                                    >
                                      WithDraw
                                    </button>
                                  ) : (
                                    "No Available"
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            Header: "Actions",
                            accessor: "deleteAction", // Use a unique accessor
                            align: "center",
                            Cell: (row) => (
                              <div>
                                <button
                                  onClick={() =>
                                    deleteRow(row?.row?.original.name.props?.id, "first")
                                  }
                                  className="btn btn-info"
                                  style={{
                                    background: "linear-gradient(195deg, #ff0000, #ff0000)",
                                    color: "white",
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            ),
                          },
                          {
                            Header: "Actions",
                            accessor: "blockAction",
                            align: "center",
                            Cell: (row) => (
                              <div>
                                <button
                                  onClick={() =>
                                    toggleBlock(
                                      row.row.original.name.props.id,
                                      row.row.original.blocked
                                    )
                                  }
                                  className="btn btn-info"
                                  style={{
                                    background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                                    color: "white",
                                  }}
                                >
                                  {row.row.original.blocked === true ? "Unblock" : "Block User"}
                                </button>
                              </div>
                            ),
                          },
                          {
                            Header: "Actions",
                            accessor: "msgAction",
                            align: "center",
                            Cell: (row) => (
                              <div>
                                <button
                                  className="btn btn-info"
                                  style={{
                                    background: "linear-gradient(195deg, #4CAF50, #8BC34A)",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    openModalForAdminMsg(
                                      row?.row?.original.name.props?.id,
                                      row?.row?.original.name.props?.name,
                                      "SingleMsg"
                                    )
                                  }
                                >
                                  Message
                                </button>
                              </div>
                            ),
                          },
                        ],
                        rows: userData.map((user) => ({
                          name: (
                            <Author
                              image={user?.profilePic}
                              name={user?.fullname}
                              id={user?._id}
                              email={user?.email}
                            />
                          ),
                          dateOfBirth: user.dateOfBirth,
                          blocked: user.isBlock,
                          phone: user.phone,
                          verifiedUser: user.verifiedUser ? "Yes" : "No",
                          gender: user.gender,
                        })),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
                <FloatButton.BackTop style={{ marginBottom: "55px", marginRight: "20px" }} />
              </Card>
            </Grid>
          </Grid>
          <ToastContainer position="top-right" autoClose={1000} />
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
      <Detail open={open} setOpen={setOpen} id={selectedId} />
    </div>
  );
}

export default Tables;
