import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { GetById } from "ApiConfig/ApiMethod";
import "../../../src/App.css";

const Detail = ({ open, setOpen, id }) => {
  const [image, setImage] = useState(null); // State to hold profile picture data
  const [front, setFront] = useState(null); // State to hold front card image data
  const [back, setBack] = useState(null); // State to hold back card image data

  const handleClose = () => setOpen(false);

  const getImage = () => {
    GetById("getUserDocs", id) // Correctly pass endpoint and id
      .then((res) => {
        console.log(res, "res");
        setImage(res?.data?.data?.profilePic); // Update state with the fetched data
        setFront(res?.data?.data?.verified?.FrontCard); // Update state with the fetched data
        setBack(res?.data?.data?.verified?.BackCard); // Update state with the fetched data
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getImage();
    }
  }, [id]);

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Detail Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display profile picture or detail content */}
        <div className="imagebody mb-2">
          <label>Profile pic</label>
          {image ? (
            <>
              <img src={image} alt="Profile Pic" className="image-profile" />
              {/* Save button for profile pic */}
              <a
                href={image}
                download="profile_pic.jpg"
                className="btn btn-primary ml-2 downloadbtn flex items-center justify-center"
              >
                Save
              </a>
            </>
          ) : (
            <p>No image available</p>
          )}
        </div>
        {/* Display front card or detail content */}
        <div className="imagebody mb-2">
          <label>Front Card</label>
          {front ? (
            <>
              <img src={front} alt="Front Card" className="image-profile" />
              {/* Save button for front card */}
              <a
                href={front}
                download="front_card.jpg"
                className="btn btn-primary ml-2 downloadbtn flex items-center justify-center"
              >
                Save
              </a>
            </>
          ) : (
            <p>No image available</p>
          )}
        </div>
        {/* Display back card or detail content */}
        <div className="imagebody">
          <label>Back Card</label>
          {back ? (
            <>
              <img src={back} alt="Back Card" className="image-profile" />
              {/* Save button for back card */}
              <a
                href={back}
                download="back_card.jpg"
                className="btn btn-primary ml-2 downloadbtn flex items-center justify-center"
              >
                Save
              </a>
            </>
          ) : (
            <p>No image available</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Add PropTypes for validation
Detail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
};

export default Detail;
