/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import Button from "@mui/material/Button";
function ProfileInfoCard({
  title,
  description,
  info,
  editData,
  action,
  shadow,
  editMode,
  setEditMode,
}) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  const [inputValue1, setInputValue1] = useState(editData.fullName);
  const [inputValue2, setInputValue2] = useState(editData.phone);
  const [inputValue3, setInputValue3] = useState(editData.email);
  const [inputValue4, setInputValue4] = useState(editData.dateOfBirth);
  const [inputValue5, setInputValue5] = useState(editData.image);
  useEffect(() => {
    setInputValue1(editData.fullName);
    setInputValue2(editData.phone);
    setInputValue3(editData.email);
    setInputValue4(editData.dateOfBirth);
    setInputValue5(editData.image);
  }, [editData]);
  // Step 2: Create a generic onChange handler
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleButton2Click = () => {
    setEditMode(!editMode);
    // Replace this with your function code
  };
  // Step 3: Modify renderItems to include the input fields
  const inputFields = [
    { label: "Full Name", type: "text", value: inputValue1, setter: setInputValue1 },
    { label: "Mobile", type: "number", value: inputValue2, setter: setInputValue2 },
    { label: "Email", type: "email", value: inputValue3, setter: setInputValue3 },
    { label: "Date of Birth", type: "date", value: inputValue4, setter: setInputValue4 },
  ];
  const handleUpdateClick = () => {
    const updateUser = async () => {
      try {
        const formData = new FormData();
        formData.append("fullname", inputValue1);
        formData.append("phone", inputValue2);
        formData.append("email", inputValue3);
        formData.append("dateOfBirth", inputValue4);
        // Add other fields as needed

        const response = await fetch(`https://backend.svips.se/user/653bed095f14d23513160adf`, {
          method: "PUT",
          body: formData,
        });

        if (response.status === 200) {
          // Handle success, e.g., show a success message to the user
          setEditMode(!editMode);
        } else {
          // Handle any other response status codes or errors
          console.error("Failed to update user information");
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error updating user information:", error);
      }
    };

    updateUser();
  };

  const renderInputFields = inputFields.map((inputField, index) => (
    <MDBox key={index} py={1} pr={2}>
      <MDBox>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {inputField.label}: &nbsp;
        </MDTypography>
      </MDBox>

      <MDInput
        type={inputField.type}
        value={inputField.value}
        onChange={handleInputChange(inputField.setter)}
        placeholder={`Enter ${inputField.label}`}
      />
    </MDBox>
  ));
  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info)?.forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info)?.forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = inputFields.map((inputField, index) => (
    <MDBox key={index} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {inputField.label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{inputField.value}
      </MDTypography>
    </MDBox>
  ));
  return (
    <Card sx={{ height: "100%", width: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {editMode ? (
            <>
              {renderInputFields}
              <MDBox
                mt={2} // Margin Top
                mr={3} // Margin Right
                space={2} // Space
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateClick}
                  m={2}
                  style={{
                    minWidth: "100px",
                    color: "white", // Text color white
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleButton2Click}
                  style={{
                    minWidth: "100px",
                    color: "#0000FF", // Primary color for Cancel button text
                    marginLeft: "8px", // Adds space between the buttons
                  }}
                >
                  Cancel
                </Button>
              </MDBox>
            </>
          ) : (
            renderItems
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  editMode: PropTypes.any,
  setEditMode: PropTypes.any,
  editData: PropTypes.shape({
    fullName: PropTypes.any,
    email: PropTypes.any,
    dateOfBirth: PropTypes.any,
    phone: PropTypes.any,
    image: PropTypes.any,

    // if there are other properties inside editData, define them here as well
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
