import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

function Transition() {
  //   const { columns, rows } = authorsTableData();
  //   const { columns: pColumns, rows: pRows } = projectsTableData();
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  //   const navigate = useNavigate("");

  useEffect(() => {
    // Fetch user data from your API here
    fetch("https://backend.svips.se/getAllUsers")
      .then((response) => response.json())
      .then((data) => {
        setUserData(data); // Assuming the data is an array of user objects
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);

  //   // Placeholder function for the button click
  //   const handleButtonClick = (userId) => {
  //     console.log(`Button clicked for user with ID: ${userId.name}`);
  //     // Add your button click logic here
  //   };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const openModal = () => {
  //   setShow(true);
  // };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Modal style={{ marginTop: "80px" }} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Given Amount:</Form.Label>
                <Form.Control type="text" placeholder="$" autoFocus />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Transition Users
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {loading ? (
                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                    </div>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Name", accessor: "name", align: "left" },
                          { Header: "Date Of Birth", accessor: "dateOfBirth", align: "left" },
                          { Header: "Phone Number", accessor: "phone", align: "left" },
                          { Header: "User Verified", accessor: "verifiedUser", align: "left" },
                          { Header: "Gender", accessor: "gender", align: "left" },

                          // Add other columns as needed

                          // Add a button column
                          {
                            Header: "Actions",
                            accessor: "actions2",
                            align: "center",
                            Cell: () => (
                              <button
                                className="btn btn-info"
                                style={{
                                  background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                                  color: "white",
                                }}
                              >
                                $500
                              </button>
                            ),
                          },
                        ],
                        rows: userData.map((user) => ({
                          name: (
                            <Author
                              image={user?.profilePic}
                              name={user?.fullname}
                              email={user?.email}
                            />
                          ),
                          dateOfBirth: user.dateOfBirth,
                          phone: user.phone,
                          verifiedUser: user.verifiedUser ? "Yes" : "No",
                          gender: user.gender,
                        })),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </div>
  );
}

export default Transition;
