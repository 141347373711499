import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { FloatButton } from "antd";
const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);
Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user data from your API here
    fetch("https://backend.svips.se/post/get-paid-posts")
      .then((response) => response.json())
      .then((data) => {
        if (data?.data) {
          setUserData(data.data); // Assuming the data is an array of user objects
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <>
        {userData.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Set the height of the container to 100% of the viewport height
            }}
          >
            No posts found
          </div>
        ) : (
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Transaction List
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={3}>
                      {loading ? (
                        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                        </div>
                      ) : (
                        <DataTable
                          table={{
                            columns: [
                              { Header: "Name", accessor: "name", align: "left" },
                              { Header: "Service Name", accessor: "serviceName", align: "left" },
                              { Header: "Status", accessor: "status", align: "left" },
                              { Header: "Price", accessor: "priceOffer", align: "left" },
                              {
                                Header: "WithDraw Amount",
                                accessor: "amount",
                                align: "left",
                              },
                              { Header: "Comment", accessor: "driverComment", align: "left" },
                              {
                                Header: "Payment Status",
                                accessor: "paymentStatus",
                                align: "left",
                              },
                              { Header: "Order Status", accessor: "orderStatus", align: "left" },
                            ],
                            rows:
                              userData?.length > 0 &&
                              userData?.map((user) => ({
                                name: (
                                  <Author
                                    image={user?.ownerId?.profilePic}
                                    name={user?.ownerId?.fullname}
                                    email={user?.ownerId?.email}
                                  />
                                ),
                                serviceName: user?.serviceName,
                                status: user?.status,
                                priceOffer: user?.priceOffer,
                                driverComment: user?.driverComment,
                                paymentStatus: user?.paymentStatus,
                                orderStatus: user?.orderStatus,
                                amount: user?.ownerId?.userCommission,
                              })),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      )}
                    </MDBox>
                    <FloatButton.BackTop style={{ marginBottom: "55px", marginRight: "20px" }} />
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            {/* <Footer /> */}
          </DashboardLayout>
        )}
      </>
    </div>
  );
}

export default Tables;
