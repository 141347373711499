import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { FloatButton } from "antd";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Put } from "ApiConfig/ApiMethod";
import Swal from "sweetalert2";
import { Delete } from "ApiConfig/ApiMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeletePost } from "ApiConfig/ApiMethod";
const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);
Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [userData, setUserData] = useState([]);
  const [model, setmodel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [editdata, seteditdata] = useState([]);
  // const [ownerId, setownerId] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setfile] = useState("");

  const getAllPost = () => {
    const token = localStorage.getItem("authToken");
    fetch("https://backend.svips.se/post/get-all-posts", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.data?.length > 0) {
          setUserData(data?.data); // Assuming the data is an array of user objects
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    // Fetch user data from your API here
    getAllPost();
    setImageUrl(editdata?.name?.props?.image || "");
  }, [editdata]);
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setfile(file);
  //   console.log(file, "fiklweee");
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImageUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  // const handleFileChange = (e, orderId) => {
  //   console.log("orderId:", orderId);
  //   const selectedFile = e.target.files[0];
  //   setfile(selectedFile);

  //   // Check if a new file is selected
  //   if (selectedFile) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       // Update the state with the new selected image for the specific order
  //       userData((prevOrders) => {
  //         console.log("prevOrders:", prevOrders);

  //         // Ensure the order ID is unique
  //         const isOrderIdUnique = prevOrders.every((order) => order._id !== orderId);

  //         if (!isOrderIdUnique) {
  //           console.error("Duplicate order ID found.");
  //           return prevOrders;
  //         }

  //         return prevOrders?.map((order) =>
  //           order._id === orderId ? { ...order, productImages: reader.result } : order
  //         );
  //       });
  //     };

  //     reader.readAsDataURL(selectedFile);
  //   }
  // };
  const handleFileChange = (e, orderId) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      setfile(selectedFile);

      reader.onloadend = () => {
        // Update the state with the new selected image for the specific order
        const filter = userData?.filter((item) => item._id === orderId);
        // console.log(filter, "filter");
        // setUserData([...filter]);
        setUserData((prevOrders) => {
          const updatedOrders = [...prevOrders]; // Shallow copy of the array

          const indexToUpdate = updatedOrders.findIndex((order) => order._id === orderId);

          if (indexToUpdate !== -1) {
            updatedOrders[indexToUpdate] = { ...updatedOrders[indexToUpdate], ...filter };
          }

          return updatedOrders;
        });
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handlePaymentStatusChange = (status) => {
    setPaymentStatus(status);
  };

  const handleClose = () => {
    setfile("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const openModal = () => setShow(true);
  const editRow = (e) => {
    setShow(true);
    seteditdata(e);
    setmodel(e);
    // console.log(URL.createObjectURL(file), "fileleell");
    // console.log(e, "dddhbd");

    // console.log(editdata, "edittttt");
    // formData.append("")
  };
  const token = localStorage.getItem("authToken");

  const Edit = (e) => {
    const formData = new FormData();
    formData.append("serviceName", model.serviceName);
    formData.append("items", model.items);
    formData.append("priceOffer", model.priceOffer);
    formData.append("ownerId", model?.name?.props?.id);
    formData.append("productImages", file);
    formData.append("orderStatus", model.orderStatus);
    formData.append("status", model.status);
    formData.append("driverComment", model.driverComment);
    formData.append("paymentStatus", model.paymentStatus);
    Put("post/edit", model?.name?.props?.id, formData, token)
      .then((res) => {
        setShow(false);
        getAllPost();
        toast.success("Edit Done");
      })
      .catch((e) => {
        toast.error("Not Edit");
      });
    // console.log(localStorage.getItem("authToken"), "kakakak");
  };
  const deleteRow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        DeletePost("post/delete", e?.name?.props?.id, token)
          .then((res) => {
            getAllPost();

            toast.success("Deletion successful");
          })
          .catch((e) => console.log(e));
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error",
        });
      }
    });
  };
  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Modal
          className="px-5 mblveiwModal"
          style={{ marginTop: "80px" }}
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Service Name:</Form.Label>
                <Form.Control
                  defaultValue={editdata?.serviceName}
                  type="text"
                  className="p-2"
                  autoFocus
                  onChange={(e) => setmodel({ ...model, serviceName: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Items:</Form.Label>
                <Form.Control
                  defaultValue={editdata?.items}
                  type="text"
                  className="p-2"
                  autoFocus
                  onChange={(e) => setmodel({ ...model, items: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Price:</Form.Label>
                <Form.Control
                  defaultValue={editdata?.priceOffer}
                  type="number"
                  className="p-2"
                  autoFocus
                  onChange={(e) => setmodel({ ...model, priceOffer: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Driver Comment:</Form.Label>
                <Form.Control
                  defaultValue={editdata?.driverComment}
                  type="text"
                  className="p-2"
                  autoFocus
                  onChange={(e) => setmodel({ ...model, driverComment: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                <Form.Label>Status:</Form.Label>
                <br />
                <select
                  className="p-1 px-3"
                  onChange={(e) => setmodel({ ...model, status: e.target.value })}
                  defaultValue={editdata?.status}
                >
                  <option value="Pick Up">Pickup</option>
                  <option value="UnPick Up">UnPickup</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Payment Status:</Form.Label>
                <br />
                <select
                  className="p-1 px-3"
                  onChange={(e) => setmodel({ ...model, paymentStatus: e.target.value })}
                  defaultValue={editdata?.paymentStatus}
                >
                  <option value="paid">Paid</option>
                  <option value="unpaid">UnPaid</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Order Status:</Form.Label>
                <br />
                {/* <Form.Control
                  defaultValue={editdata?.orderStatus}
                  type="text"
                  className="p-2"
                  autoFocus
                  onChange={(e) => setmodel({ ...model, orderStatus: e.target.value })}
                /> */}
                <select
                  className="p-1 px-3"
                  onChange={(e) => setmodel({ ...model, orderStatus: e.target.value })}
                  defaultValue={editdata?.orderStatus}
                >
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Product Image:</Form.Label>
                <Form.Control
                  type="file"
                  className="p-2"
                  onChange={(e) => handleFileChange(e, model?.name?.props?.id)}
                />

                <img
                  // src={file ? URL.createObjectURL(file) : editdata.orderimage}
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : Array.isArray(model?.orderimage) && model?.orderimage[0]
                  }
                  alt="Preview"
                  style={{ marginTop: "10px", maxWidth: "100px" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary mb-5" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary mb-5" onClick={Edit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Order List
                  </MDTypography>
                </MDBox>

                <MDBox pt={3}>
                  {loading ? (
                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                      <Skeleton animation={true} height={"50px"} />
                    </div>
                  ) : userData?.length === 0 ? (
                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                      <h4 className="text-center p-5">No Orders Found</h4>
                    </div>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Name", accessor: "name", align: "left" },
                          { Header: "Service Name", accessor: "serviceName", align: "left" },
                          { Header: "Status", accessor: "status", align: "left" },
                          { Header: "Price", accessor: "priceOffer", align: "left" },
                          { Header: "Comment", accessor: "driverComment", align: "left" },
                          { Header: "Payment Status", accessor: "paymentStatus", align: "left" },
                          { Header: "Order Status", accessor: "orderStatus", align: "left" },
                          // Add other columns as needed
                          {
                            Header: "Actions",
                            accessor: "editAction", // Use a unique accessor
                            align: "center",
                            Cell: (row) => (
                              <button
                                onClick={() => editRow(row?.row?.original, "dddhbd")}
                                className="btn btn-info"
                                style={{
                                  background: "linear-gradient(195deg, #4CAF50, #8BC34A)",
                                  color: "white",
                                }}
                              >
                                Edit Details
                              </button>
                            ),
                          },
                          {
                            Header: "Actions",
                            accessor: "DeleteAction", // Use a unique accessor
                            align: "center",
                            Cell: (row) => (
                              <button
                                onClick={() => deleteRow(row?.row?.original, "dddhbd")}
                                className="btn btn-info"
                                style={{
                                  background: "linear-gradient(195deg, #ff0000, #ff0000)",
                                  color: "white",
                                }}
                              >
                                Delete Order
                              </button>
                            ),
                          },
                        ],
                        rows: userData?.map((user) => ({
                          name: (
                            <Author
                              image={user?.ownerId?.profilePic}
                              name={user?.ownerId?.fullname}
                              email={user?.ownerId?.email}
                              id={user?._id}
                            />
                          ),
                          serviceName: user.serviceName,
                          status: user.status,
                          orderimage: user.productImages,
                          items: user.items,
                          priceOffer: user.priceOffer,
                          driverComment: user.driverComment,
                          paymentStatus: user.paymentStatus,
                          orderStatus: user.orderStatus,
                        })),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
                <FloatButton.BackTop style={{}} />
              </Card>
            </Grid>
          </Grid>
          <ToastContainer position="top-right" autoClose={1000} />
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </div>
  );
}

export default Tables;
